<template>
    <div class="content">
        <ValidationObserver
            v-show="flagState === 'input'"
            ref="observer"
            v-slot="{ invalid }"
            tag="div"
        >
            <h1 class="pagetitle">
                お客様情報入力
            </h1>
            <div>
                <h2>
                    {{ field[collection[0].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[0]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[0].field]"
                        @input="input[collection[0].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[1].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[1]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[1].field]"
                        @input="input[collection[1].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[2].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[2]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[2].field]"
                        @input="input[collection[2].field] = $event"
                        @address="input[collection[3].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[3].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[3]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[3].field]"
                        @input="input[collection[3].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[4].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[4]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[4].field]"
                        @input="input[collection[4].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[5].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[5]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[5].field]"
                        @input="input[collection[5].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[6].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[6]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input.paymentMethod"
                        :undo="undo.paymentMethod"
                        @input="confirmPaymentMethod($event)"
                    />
                </div>
                <h2>
                    {{ field[collection[7].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[7]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[7].field]"
                        @input="checkedReceipt = $event"
                    />
                </div>
                <template v-if="input[collection[7].field] === 'あり'">
                    <h2>
                        {{ field[collection[8].field].head }}
                    </h2>
                    <div>
                        <Input
                            :data="collection[8]"
                            :flag-validated-immediate="flagValidatedImmediate"
                            :input="input[collection[8].field]"
                            @input="input[collection[8].field] = $event"
                        />
                    </div>
                    <h2>
                        {{ field[collection[9].field].head }}
                    </h2>
                    <div>
                        <Input
                            :data="collection[9]"
                            :flag-validated-immediate="flagValidatedImmediate"
                            :input="input[collection[9].field]"
                            @input="input[collection[9].field] = $event"
                        />
                    </div>
                    <h2>
                        {{ field[collection[10].field].head }}
                    </h2>
                    <div>
                        <Input
                            :data="collection[10]"
                            :flag-validated-immediate="flagValidatedImmediate"
                            :input="input[collection[10].field]"
                            @input="input[collection[10].field] = $event"
                        />
                    </div>
                </template>
            </div>
            <div :class="$style.checkbox">
                <input v-if="flagSendData" id="flagSendData" :value="flagSendData" type="checkbox" checked @click="confirmSendData($event)">
                <input v-else id="flagSendData" :value="flagSendData" type="checkbox" @click="confirmSendData($event)">
                <label for="flagSendData">
                    お客様情報入力の住所と別の場所へ商品お届け希望
                </label>
            </div>
            <div v-if="flagSendData">
                <div class="pagetitle" :class="$style.heading">
                    送り先情報入力
                </div>
                <h2>
                    {{ field[collection[11].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[11]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[11].field]"
                        @input="input[collection[11].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[12].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[12]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[12].field]"
                        @input="input[collection[12].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[13].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[13]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[13].field]"
                        @input="input[collection[13].field] = $event"
                    />
                </div>
                <h2>
                    {{ field[collection[14].field].head }}
                </h2>
                <div>
                    <Input
                        :data="collection[14]"
                        :flag-validated-immediate="flagValidatedImmediate"
                        :input="input[collection[14].field]"
                        @input="input[collection[14].field] = $event"
                    />
                </div>
            </div>
            <div class="buttonArea">
                <div>
                    <Button
                        :disabled="invalid"
                        text="注文内容確認へ進む"
                        type="next"
                        @action="buttonNext()"
                    />
                </div>
                <div>
                    <Button
                        text="商品注文一覧へ戻る"
                        type="textlink"
                        @action="buttonBackToList()"
                    />
                </div>
            </div>
        </ValidationObserver>
        <div v-show="flagState === 'confirm'">
            <h1 class="pagetitle">
                ご注文内容
            </h1>
            <div>
                <div v-for="(data, index) in input.order" :key="`item${index}`" :class="$style.item">
                    <div :class="$style.item__basic">
                        <div :class="[$style.item__basic__block, $style.itemImage]">
                            <img v-if="image[data.itemId][0]" :class="$style.item__basic__block__image" :src="image[data.itemId][0]" :alt="data.itemName" />
                        </div>
                        <div :class="$style.item__basic__block">
                            <h2 v-if="data.itemName" :class="$style.item__basic__block__title">
                                {{ data.itemName }}
                            </h2>
                            <div v-if="data.price" :class="$style.item__basic__block__price">
                                <span :class="$style.item__basic__block__price__tax">
                                    税込
                                </span>
                                {{ data.price }}{{ field.price.unit }}
                            </div>
                            <p :class="$style.item__basic__block__quantity">
                                注文数：{{ data.quantity }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="pagetitle" :class="$style.heading">
                お客様情報
            </h2>
            <table>
                <tr>
                    <th>
                        {{ field[collection[0].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[0].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[1].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[1].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[2].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[2].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[3].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[3].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[4].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[4].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[5].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[5].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[6].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[6].field] }}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{ field[collection[7].field].head }}
                    </th>
                </tr>
                <tr>
                    <td>
                        {{ input[collection[7].field] }}
                        <div v-if="input[collection[7].field] === 'あり'" :class="$style.inner">
                            <div :class="$style.inner__block">
                                {{ field[collection[8].field].head }}：{{ input[collection[8].field] }}
                            </div>
                            <div :class="$style.inner__block">
                                {{ field[collection[9].field].head }}：{{ input[collection[9].field] }}
                            </div>
                            <div :class="$style.inner__block">
                                {{ field[collection[10].field].head }}：{{ input[collection[10].field] }}
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <template v-if="flagSendData">
                <h2 class="pagetitle" :class="$style.heading">
                    送り先情報
                </h2>
                <table>
                    <tr>
                        <th>
                            {{ field[collection[11].field].head }}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {{ input[collection[11].field] }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            {{ field[collection[12].field].head }}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {{ input[collection[12].field] }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            {{ field[collection[13].field].head }}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {{ input[collection[13].field] }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            {{ field[collection[14].field].head }}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {{ input[collection[14].field] }}
                        </td>
                    </tr>
                </table>
            </template>
            <h2 class="pagetitle" :class="$style.heading">
                お支払金額
            </h2>
            <div :class="$style.payment">
                <table :class="$style.payment__table">
                    <tr :class="$style.payment__table__row">
                        <th :class="$style.payment__table__row__head">
                            小計
                        </th>
                        <td :class="$style.payment__table__row__data">
                            <span :class="$style.payment__table__row__data__tax">
                                税込
                            </span>
                            {{ input.subTotal }}円
                        </td>
                    </tr>
                </table>
                <table :class="$style.payment__table">
                    <tr :class="$style.payment__table__row">
                        <th :class="$style.payment__table__row__head">
                            送料
                        </th>
                        <td :class="$style.payment__table__row__data">
                            <span :class="$style.payment__table__row__data__tax">
                                税込
                            </span>
                            {{ input.shippingFee }}円
                        </td>
                    </tr>
                </table>
                <table :class="$style.payment__table">
                    <tr :class="$style.payment__table__row">
                        <th :class="$style.payment__table__row__head">
                            合計
                        </th>
                        <td :class="$style.payment__table__row__data__total">
                            <span :class="$style.payment__table__row__data__total__tax">
                                税込
                            </span>
                            {{ input.total }}円
                        </td>
                    </tr>
                </table>
            </div>
            <div class="buttonArea">
                <div>
                    <Button
                        text="注文する"
                        type="next"
                        @action="create()"
                    />
                </div>
                <div>
                    <Button
                        text="お客様情報入力へ戻る"
                        type="textlink"
                        @action="buttonBack()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    "use strict";

    import detail from "@/mixins/detail.js";

    export default {
        "mixins": [
            detail,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "flagSendData": false,
                "flagState": "input",
                "image": {},
                "undo": {
                    "paymentMethod": 1,
                },
            };
        },
        "computed": {
            // 領収証なしの場合、宛名と但し書きをリセット
            "checkedReceipt": {
                get() {
                    return this.input.receipt;
                },
                set(value) {
                    if (value === "なし") {
                        this.input.receiptAddress = "";
                        this.input.receiptDescription = "";
                        this.input.receiptInclude = "お客様情報入力住所へ郵送";
                    }
                    this.input.receipt = value;
                },
            },
        },
        created() {
            this.$_created();
            if (Object.keys(this.$route.query).length < 1) {
                // 選択項目がない場合は一覧へもどる
                this.$router.push({
                    "name": "item",
                });
            }
            else {
                const process = [
                    this.$_firestoreReadList({
                        "collection": "items",
                    }),
                ];
                Promise.all(process).then(response => {
                    const items = this.$_firestoreReadListObject(response[0]);
                    this.makeOrders(items).then(() => {
                        this.calcTotal();
                    });
                });
            }
        },
        "methods": {
            // 代金引換を選択時に、別途送り先指定があるかを判定
            confirmPaymentMethod(event) {
                if (event === "代金引換" && this.flagSendData) {
                    const flag = confirm("商品お届け先での代金お支払いとなりますがよろしかったでしょうか。");
                    this.input.paymentMethod = "none";
                    if (flag) {
                        this.input.paymentMethod = event;
                    }
                    else {
                        this.undo.paymentMethod++;
                    }
                }
                else {
                    this.input.paymentMethod = event;
                }
            },
            // 別途送り先指定を選択時に、代金引換かを判定
            confirmSendData(event) {
                if (this.flagSendData) {
                    // 別途送り先希望なしの場合リセット
                    this.input.sendName = "";
                    this.input.sendTel = "";
                    this.input.sendPostalCode = "";
                    this.input.sendAddress = "";
                    this.flagSendData = !this.flagSendData;
                }
                else if (this.input.paymentMethod === "代金引換" && !this.flagSendData) {
                    const flag = confirm("商品お届け先での代金お支払いとなりますがよろしかったでしょうか。");
                    if (flag) {
                        this.flagSendData = !this.flagSendData;
                    }
                    else {
                        event.preventDefault();
                    }
                }
                else {
                    this.flagSendData = !this.flagSendData;
                }
            },
            // ページトップへ
            scrollTop() {
                const currentY = window.pageYOffset;
                if (currentY !== 0) {
                    scrollBy(0, -currentY);
                }
            },
            buttonBackToList() {
                this.$router.push({
                    "name": "item",
                });
            },
            buttonBack() {
                this.scrollTop();
                this.flagState = "input";
            },
            buttonNext() {
                this.flagState = "confirm";
                this.scrollTop();
            },
            // 送料を計算
            calcShippingFee() {
                const value = 860;
                this.$set(this.input, "shippingFee", value);
                return value;
            },
            // 小計を計算
            calcSubTotal() {
                let subTotal = 0;
                for (const data of this.input.order) {
                    subTotal += Number(data.price) * Number(data.quantity);
                }
                this.$set(this.input, "subTotal", subTotal);
                return subTotal;
            },
            // 合計を計算
            calcTotal() {
                const shippingFee = this.calcShippingFee();
                const subTotal = this.calcSubTotal();
                const total = shippingFee + subTotal;
                this.$set(this.input, "total", total);
            },
            // 注文商品をset
            makeOrders(items) {
                const order = [];
                for (const key in this.$route.query) {
                    order.push({
                        "itemId": key,
                        "itemName": items[key].itemName,
                        "quantity": Number(this.$route.query[key]),
                        "price": Number(items[key].price),
                    });
                    this.$set(this.image, key, "");
                    const input = {
                        "fileName": items[key].image,
                    };
                    return this.$_fileDownload(input).then(response => {
                        this.image[key] = response;
                        this.$set(this.input, "order", order);
                    });
                }
            },
            create() {
                this.input.orderDatetime = this.$_firestoreTimestamp();
                this.input.printedDatetime = null;
                this.input.depositedDatetime = null;
                this.input.sentDatetime = null;
                this.$_firestoreCreate({
                    "collection": this.management.collection,
                    "set": this.input,
                }).then(() => {
                    this.$router.push({
                        "name": `${this.name}_create_complete`,
                    });
                }).catch(() => {
                    alert("登録に失敗しました。");
                });
            },
        },
    };
</script>

<style lang="scss" module>
    h2 {
        margin-bottom: .5em;
        &:not(:first-of-type) {
            margin-top: 2em;
        }
        &.heading {
            margin-top: 3em;
        }
    }

    textarea {
        display: block;
    }

    table {
        border-top: 1px solid #ddd;
    }

    th,
    td {
        background: #fff;
        border-bottom: 1px solid #ddd;
        line-height: 1.6;
        padding: 12px;
        vertical-align: top;
    }
    
    th {
        background: #f5f5f5;
        font-weight: bold;
    }

    .checkbox {
        margin-top: 56px;
    }

    .item {
        background: #fff;
        border: 1px solid #ddd;
        margin: 24px 0 0;
        &__basic {
            display: flex;
            width: 100%;
            &__block {
                padding: 16px;
                width: 50%;
                &__title {
                    font-size: 16px;
                    line-height: 1.4;
                }
                &__price {
                    color: #e00;
                    font-size: 18px;
                    line-height: 1.6;
                    margin: 8px 0 0;
                    &__tax {
                        font-size: 12px;
                        margin-right: 2px;
                    }
                }
                &__quantity {
                    margin: 12px 0 0;
                }
            }
        }
    }

    .itemImage {
        background: #f5f5f5;
    }

    .payment {
        &__table {
            margin-top: 16px;
            &__row {
                &__head {
                    width: 32%;
                }
                &__data {
                    &__tax {
                        font-size: 12px;
                        margin-right: 2px;
                    }
                    &__total {
                        color: #e00;
                        font-size: 21px;
                        line-height: 1.2;
                        &__tax {
                            font-size: 12px;
                            margin-right: 2px;
                        }
                    }
                }
            }
        }
    }

    .inner {
        margin-top: 32px;
        &__block {
            margin-top: 2px;
        }
    }
</style>
